// --- Third Party Libraries ---
import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { AiOutlineCopyright } from "react-icons/ai";
import { useTranslation } from "react-i18next";

// --- Internal Components ---
import logo from "../../assets/img/horizontal-logo-for-black-background.png";

// --- Constants ---
import { FOOTER_NAV_LINKS } from "../../utils/constants";

// --- Footer Component ---
const Footer = () => {
  // --- Hooks ---
  const { t } = useTranslation();

  // --- Render Component ---
  return (
    <Box className="footer-section">
      <img src={logo} alt="Logo" />
      <Stack direction="row" className="footer-links">
        {FOOTER_NAV_LINKS.map((link, index) => (
          <Link key={index} to={link.path}>
            <Typography variant="body1">{t(link.labelKey)}</Typography>
          </Link>
        ))}
      </Stack>
      <Typography className="footer-rights" variant="body2">
        <AiOutlineCopyright />2024 Intelswift Inc. {t("landingHeader.footerRights")}
      </Typography>
    </Box>
  );
}

export default Footer;