import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./locales/en.json";
import ukTranslation from "./locales/uk.json";

const resources = {
  en: {
    translation: enTranslation
  },
  uk: {
    translation: ukTranslation
  }
};

const language = localStorage.getItem("selectedLanguage") || "en";
console.log("@@@ selected language ", language);
i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
