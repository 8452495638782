import React, { useState, useEffect } from "react";
import { Grid, Card, CardHeader, CardContent, CardActions, Typography, Button, Box, Switch, useMediaQuery } from "@mui/material";
import { IoIosCheckmarkCircle } from "react-icons/io";
import PlansComparisonTable from "./comparison";
import { plansData, featureLabels } from "./comparison";
import CustomSwitch from "../../components/CustomSwitch";

export default function PlansCard({ plans }) {
    const [planType, setPlanType] = useState("Monthly");
    const [showStickyHeader, setShowStickyHeader] = useState(false);
    const [showCards, setShowCards] = useState(true);
    const [showPriceHeader, setShowPriceHeader] = useState(false);
    const isMediumScreenOrSmaller = useMediaQuery("(max-width:960px)");

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 200) {
            setShowStickyHeader(true);
            setShowCards(false);
            setTimeout(() => {
                setShowPriceHeader(true);
            }, 200);
        } else {
            setShowStickyHeader(false);
            setShowCards(true);
            setShowPriceHeader(false);
        }
    };

    useEffect(() => {
        if (!isMediumScreenOrSmaller) {
            window.addEventListener("scroll", handleScroll);
        } else {
            setShowStickyHeader(false);
            setShowCards(true);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isMediumScreenOrSmaller]);

    const getFilteredPlans = () => {
        const allPlans = [...plans];
        const isAnnual = planType === "Annual";
        return allPlans.filter(plan => {
            if (isAnnual) {
                return plan.name.includes("annual");
            } else {
                return !plan.name.includes("annual");
            }
        });
    };

    const calcPlanName = (name) => name.replace("annual", "").trim();

    const calcPlanPrice = (price) => {
        console.log("price ", price);
        return (planType === "Annual") ? Math.round(price / 12) : price;
    };

    const renderPlanFeatures = (planName) => {
        const plan = plansData.find(p => p.plan === planName);
        if (!plan) return null;
        const filteredFeatures = featureLabels.filter(feature => {
            const value = plan.features[feature.key];
            return (typeof value === "boolean" && value) || (typeof value !== "boolean" && value !== "");
        });

        return (
            <Box component="ul">
                {filteredFeatures.map((feature, index) => (
                    <Box component="li" key={index}>
                        <Typography variant="body1">
                            {feature.label}: {typeof plan.features[feature.key] === "boolean" ?
                                (plan.features[feature.key] ? <IoIosCheckmarkCircle /> : "✖") :
                                plan.features[feature.key]}
                        </Typography>
                    </Box>
                ))}
            </Box>
        );
    };

    const renderPlanCard = (plan, id) => {
        return (
            <Grid item key={`${plan.name}-${id}`} xs={12} sm={12} md={12} lg={3.0} className={`plan-card ${showCards ? "visible" : "hidden"}`}>
                <Card key={plan.name} variant="gradient" className={`card-plan card-plan-${id}`}>
                    <div className={`plan-header-gradient-${id}`}></div>
                    <div className="plan-header">
                        <CardHeader
                            title={
                                <>
                                    <Typography variant="h5" className={`plan-name plan-name-${id}`}>
                                        {calcPlanName(plan.name)}
                                    </Typography>
                                    <Typography variant="subtitle1" className="plan-price">
                                        ${calcPlanPrice(plan.price)}<span className="plan-month">per month</span>
                                    </Typography>
                                </>
                            }
                        />
                        <Box className="plan-subtitle-wrapper">
                            <Typography variant="subtitle2" className="plan-subtitle">
                                {plan.name.includes("Personal") && "Ideal for individuals evaluating the product"}
                                {plan.name.includes("Pro") && "Perfect for small & growing teams"}
                                {plan.name.includes("Business") && "Ideal for businesses scaling their support"}
                            </Typography>
                        </Box>
                        {isMediumScreenOrSmaller && (
                            <CardContent>
                                {renderPlanFeatures(plan.name)}
                            </CardContent>
                        )}
                    </div>
                </Card>
            </Grid>
        );
    };

    const renderPricingPlanContainerCard = () => (
        <Grid item xs={12} sm={12} md={12} lg={3.0} className={`plan-card-container ${showCards ? "visible" : "hidden"}`}>
            <Box className="title-container">
                <Typography className="title-pricing" variant="h3">No hidden fees ever</Typography>
                <Typography className="subtitle-pricing" variant="h4">Choose the plan that is right for you</Typography>
                <Box className="switch-container">
                    <Typography className="switch-container-label" variant="subtitle1">Monthly</Typography>
                    <CustomSwitch
                        checked={planType === "Annual"}
                        onChange={(e) => setPlanType(e.target.checked ? "Annual" : "Monthly")}
                        name="planType"
                    />
                    <Typography className="switch-container-label" variant="subtitle1">Annual -20%</Typography>
                </Box>
            </Box>
        </Grid>
    );

    const filteredPlans = getFilteredPlans();

    const renderStickyHeaderPlan = (plan, id) => (
        <div className="card-sticky">
            <Typography variant="h6">{calcPlanName(plan.name)}</Typography>
            <div className={`price-header-container ${showPriceHeader ? "visible" : ""}`}>
                <Typography variant="subtitle1" className="plan-price-header">
                    ${calcPlanPrice(plan.price)}<span className="plan-month">per month</span>
                </Typography>
            </div>
        </div>
    );

    return (
        <div>
            <Grid container spacing={1} className="inline-block-grid-item" justifyContent="center">
                {renderPricingPlanContainerCard()}
                {filteredPlans.length > 0 && filteredPlans.map((plan, index) => renderPlanCard(plan, index))}
            </Grid>
            {!isMediumScreenOrSmaller && <PlansComparisonTable />}
            {!isMediumScreenOrSmaller && (
                <div className={`header-sticky ${showStickyHeader ? "visible" : "hidden"}`}>
                    <div className="header-container-sticky">
                        <Box className="header-title-container">
                            <Typography className="header-title" variant="h2">Compare plans</Typography>
                            <Box className="switch-container">
                                <Typography className="switch-container-label" variant="subtitle1">Monthly</Typography>
                                <CustomSwitch
                                    checked={planType === "Annual"}
                                    onChange={(e) => setPlanType(e.target.checked ? "Annual" : "Monthly")}
                                    name="planType"
                                />
                                <Typography className="switch-container-label" variant="subtitle1">Annual -20%</Typography>
                            </Box>
                        </Box>
                        {filteredPlans.slice(0, 4).map((plan, index) => renderStickyHeaderPlan(plan, index))}
                    </div>
                </div>
            )}
        </div>
    );
}
