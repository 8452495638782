// --- Third Party Libraries ---
import React from "react";
import { Typography, Button, Box, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// --- Image Utilities ---
import { tryForFreeImages } from "../../utils/imageUtils";

// --- Constants ---
import { DASHBOARD_URL } from "../../utils/constants";

// --- TryForFree Component ---
const TryForFree = () => {
    // --- Hooks ---
    const navigate = useNavigate();
    const { t } = useTranslation();

    // --- Render Component ---
    return (
        <Box className="try-for-free-section">
            <Card className="try-for-free-card">
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} position="relative">
                    <Box
                        className="try-for-free-card-left-content"
                        flex={{ xs: '1 1 100%', md: '1 1 50%' }}
                    >
                        <Typography variant="h3">Level Up Your Customer Service With AI</Typography>
                        <Typography variant="subtitle1">
                            We are the ultimate painkiller for businesses trying to bring AI into their marketing, sales, and support
                        </Typography>
                        <Button 
                            onClick={() => {
                                window.open("https://app.intelswift.com/", "_blank")
                            }} 
                            variant="contained"
                        >
                            Try for free
                        </Button>
                    </Box>
                    <Box
                        className="try-for-free-card-right-content"
                        flex={{ xs: '1 1 100%', md: '1 1 50%' }}
                    >
                        <img src={tryForFreeImages.Logomark} alt="Logomark" />
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};

export default TryForFree;