// --- Third Party Libraries ---
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  Popover,
} from "@mui/material";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";

// --- Image Utilities ---
import { headerImages } from "../../utils/imageUtils";

// --- Constants ---
import { MAIN_APP_URL, GET_DEMO_URL, NAV_LINKS, MENU_ITEMS } from "../../utils/constants";

// --- Header Component ---
const Header = () => {
  // --- Hooks and State ---
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElLang, setAnchorElLang] = useState(null);
  const browserLanguage = navigator.language.toLowerCase();
  const defaultLanguage = browserLanguage.startsWith("en") ? "en" : "uk";
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const languages = [
    { id: "en", name: "English" },
    { id: "uk", name: "Українська" },
  ];

  // --- useEffect Hooks ---
  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    const languageToSet = storedLanguage || defaultLanguage;
    setSelectedLanguage(languageToSet);
    i18n.changeLanguage(languageToSet);
  }, [i18n, defaultLanguage]);

  // --- Event Handlers ---
  const handleLanguageSelection = (language) => {
    if (selectedLanguage !== language) {
      setSelectedLanguage(language);
      i18n.changeLanguage(language);
      localStorage.setItem("selectedLanguage", language);
      handleCloseLangPopover();
    }
  };

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);

  const handleOpenLangPopover = (event) => setAnchorElLang(event.currentTarget);
  const handleCloseLangPopover = () => setAnchorElLang(null);

  const handleNavigate = (url, isExternal = false) => {
    if (isExternal) {
      window.open(url, "_blank");
    } else {
      navigate(url);
    }
  };

  // --- Render Functions ---
  const renderLanguagePopover = () => (
    <Popover
      id="lang-appbar"
      open={Boolean(anchorElLang)}
      anchorEl={anchorElLang}
      onClose={handleCloseLangPopover}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Stack spacing={1}>
        {languages.map((language) => (
          <MenuItem
            key={language.id}
            onClick={() => handleLanguageSelection(language.id)}
            disabled={selectedLanguage === language.id}
          >
            {language.name}
          </MenuItem>
        ))}
      </Stack>
    </Popover>
  );

  const renderNavigationMenu = () => (
    <Menu
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
    >
      {MENU_ITEMS.map((item, index) => (
        <MenuItem
          key={index}
          onClick={() => handleNavigate(item.path, item.isExternal)}
        >
          <Typography textAlign="center">{item.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );

  // --- Render Component ---
  return (
    <AppBar className="header-wrapper">
      <Toolbar className="header-container">
        <Link to="/">
          <img className="header-logo" src={headerImages.logo} alt="Logo" />
        </Link>
        <Stack className="language-selection-stack" direction="row" spacing={1}>
          <Button
            aria-label="language selection"
            aria-controls="lang-appbar"
            aria-haspopup="true"
            onClick={handleOpenLangPopover}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <Typography variant="body2" className="language-selection">
              <IoIosArrowDown className="language-selection-icon" />
              {selectedLanguage === "en" ? "En" : "Uk"}
            </Typography>
          </Button>
          {renderLanguagePopover()}
          <Box className="burger-menu">
            <IconButton
              size="large"
              aria-label="navigation menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <RxHamburgerMenu className="burger-menu-icon" />
            </IconButton>
            {renderNavigationMenu()}
          </Box>
        </Stack>
        <Stack className="header-middle-section" direction="row">
          {NAV_LINKS.map((link, index) => (
            <Link key={index} to={link.path}>
              <Typography variant="body1">{t(link.labelKey)}</Typography>
            </Link>
          ))}
        </Stack>
        <Stack className="header-right-section" spacing={2} direction="row">
          <Button
            aria-label="language selection"
            aria-controls="lang-appbar"
            aria-haspopup="true"
            onClick={handleOpenLangPopover}
          >
            <Typography variant="body2" className="language-selection">
              <IoIosArrowDown className="language-selection-icon" />
              {selectedLanguage === "en" ? "En" : "Uk"}
            </Typography>
          </Button>
          {renderLanguagePopover()}
          <Button
            className="login-button"
            onClick={() =>
              window.open(MAIN_APP_URL, "_self", "rel=noopener noreferrer")
            }
          >
            Login
          </Button>
          <Button className="demo-button" href={GET_DEMO_URL} target="_blank">
            Get a demo
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
