import React, { useState, useEffect } from "react";
import { Typography, Box, Tabs, Tab, Divider } from "@mui/material";
import { mainImages } from "../../utils/imageUtils";

const Main = () => {
  const [activeTab, setActiveTab] = useState(4);
  const [loadedTab, setLoadedTab] = useState(4);

  useEffect(() => {
    setLoadedTab(activeTab);
  }, [activeTab]);

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const tabData = [
    "Apps and Integrations",
    "Tickets",
    "Segments",
    "Swift AI Copilot",
    "Omnichannel",
    "AI Agents",
    "Workflows",
    "LiveChat",
    "Reporting",
  ];

  const tabContent = [
    { image: mainImages.integrations, description: "Automate your business processes in a single place, by utilizing our integrations with over 120 applications" },
    { image: mainImages.tickets, description: "AI agent will create a support ticket automatically at the time it is not able to resolve complex customers issue. " },
    { image: mainImages.segments, description: "No more manual lead qualification, let AI do it for you" },
    { image: mainImages.co_pilot, description: "Help your support team with AI-assisted insights and suggestions, so they can resolve customer inquiries faster and meet customer expectations. The AI copilot is trained to provide real-time suggestions and relevant information to support agents during customer interactions, improving resolution accuracy and efficiency." },
    {
      images: mainImages.omnichannel.main,
      icons: mainImages.omnichannel.icons,
      description: "Manage all customer communications in one centralized platform, and never miss a message. Consolidate messages from multiple channels (email, chat, social media) into a single interface, and ensure comprehensive customer interaction tracking."
    },
    { image: mainImages.ai_agents, description: "Train your intelligent virtual assistants to handle routine inquiries, freeing your team to focus on complex issues. AI-powered chatbots automatically respond to common customer questions, reducing response times and workload on human agents." },
    { image: mainImages.workflows, description: "Build powerful automations with our no code visual builder." },
    { image: mainImages.inbox, description: "Maximize productivity with an AI-enhanced livechat." },
    { image: mainImages.reporting, description: "Discover anomalies in your daily conversations, find patterns, and make only data-driven decisions" },
  ];

  const renderImages = (images, index) => (
    <div className="image-container">
      <div className="icon-row">
        {tabContent[index].icons.map((icon, iconIndex) => (
          <img
            key={iconIndex}
            className="omnichannel-icon"
            src={icon}
            alt={`Omnichannel Icon ${iconIndex + 1}`}
          />
        ))}
      </div>
      {images.map((image, imgIndex) => (
        <img
          key={imgIndex}
          className={`main-tab-image main-tab-image-${imgIndex + 1} ${loadedTab === index ? "active" : ""}`}
          src={image}
          alt={`${tabContent[index].label}-${imgIndex + 1}`}
        />
      ))}
    </div>
  );

  return (
    <Box className="main-section">
      <Typography className="main-section-title" variant="h1">
        AI-first Customer Service Automation Software
      </Typography>
      <Tabs
        className="main-tabs-list"
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabData.map((label, index) => (
          <Tab
            key={index}
            label={
              <div className={activeTab === index ? "active-tab" : ""}>
                <Typography className="main-tab-label-title" variant="h5">{label}</Typography>
              </div>
            }
          />
        ))}
      </Tabs>
      <Divider className="main-tabs-divider" />
      <div className="main-tab-content">
        {tabContent.map((content, index) => (
          <div key={index} className={`main-tab-panel ${activeTab === index ? "active" : ""}`}>
            <Typography className="main-tab-description" variant="subtitle1">
              {content.description}
            </Typography>
            {Array.isArray(content.images)
              ? renderImages(content.images, index)
              : (
                <img
                  className={`main-tab-image ${loadedTab === index ? "active" : ""}`}
                  src={content.image}
                  alt={content.label}
                />
              )
            }
          </div>
        ))}
      </div>
    </Box>
  );
}

export default Main;
