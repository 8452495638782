import React from "react";

const Conditions = () => {
    return (
        <section className="terms-of-conditions">
            <h2 className="terms-of-conditions-header"><strong>Terms of Services</strong></h2>
            <p>Last updated: June 10, 2024</p>

            <p>Please read these Terms of Services carefully before using Our Service.</p>

            <h3><strong>Interpretation and Definitions</strong></h3>

            <h3><strong>Interpretation</strong></h3>

            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

            <h3><strong>Definitions</strong></h3>

            <p>For the purposes of these Terms and Conditions:</p>
            <ul>
                <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
                <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
                <li><strong>AI Assistant</strong> is a highly skilled chatbot that has been specifically trained to assist you with your business needs. The AI assistant has the capability to be assigned to multiple channels. Various AI assistants can be developed to accomplish diverse business objectives.</li>
                <li><strong>Country</strong> refers to: Estonia</li>
                <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Intelswift OÜ, Harju maakond, Tallinn, Kesklinna linnaosa, Vesivärava tn 50-201, 10152,  Estonia.</li>
                <li><strong>Company’s platform</strong> or <strong>“Platform”</strong> – online platform located on <a href="https://app.Intelswift.com/">https://app.Intelswift.com/</a> that is used by the Customer in accordance with the Terms.</li>
                <li><strong>Content</strong> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.</li>
                <li><strong>Customer Data</strong> – any Customer’s information used in Customer’s Knowledge Bank or any other data, used on Customer’s own discretion.</li>
                <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                <li><strong>Feedback</strong> means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.</li>
                <li><strong>Free Trial</strong> refers to a limited period of time that may be free when purchasing a Subscription.</li>
                <li><strong>Knowledge Bank</strong> – a centralized repository or database, stored and managed by the Customer, formed within a vast amount of structured and unstructured data, information, and knowledge that serves as the backbone for AI models and algorithms to access, process, and derive insights from the aggregated data.</li>
                <li><strong>Permitted Users</strong> in these Terms are the users of the Platform that clearly defined by the Customer who can access the Service, as well as their roles and permissions.</li>
                <li><strong>Promotions</strong> refer to contests, sweepstakes or other promotions offered through the Service.</li>
                <li><strong>Service</strong> refers to the Website as specified below in chapter Services.</li>
                <li><strong>Subscriptions</strong> refer to the services or access to the Service offered on a subscription basis by the Company to You.</li>
                <li><strong>Subscription Term</strong> – the prepaid period when the Customer has a possibility to use the Services.</li>
                <li><strong>Terms of Services</strong> (also referred as "Terms") mean these Terms of Services that form the entire agreement between You and the Company regarding the use of the Service.</li>
                <li><strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</li>
                <li><strong>Website</strong> refers to Intelswift, accessible from <a href="https://Intelswift.com">https://Intelswift.com</a></li>
                <li><strong>“You” or “Customer”</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
            </ul>

            <h3><strong>Acknowledgment</strong></h3>

            <p>These are the Terms governing the use of this Service and the agreement that operates between You and the Company. These Terms set out the rights and obligations of all users regarding the use of the Service.
                Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
                By accessing or using the Service You agree to be bound by these Terms. If You disagree with any part of these Terms then You may not access the Service.
                If you do not agree with this Terms, please refrain from using any Services. For the avoidance of doubt, each party explicitly acknowledges that this Terms is legally binding upon it.
                You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
                Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>

            <h3><strong>Services</strong></h3>

            <p>Company's Services consist of a customer service-oriented software-as-a-service (SaaS) solutions provided through an online Company’s platform <a href="https://app.Intelswift.com/">https://app.Intelswift.com/</a>. These Services are designed to enable the Customer to manage interactions with its clients supporting their relationship.
                Each Service is offered on a subscription basis for a defined term as specified in the “Pricing” chapter of Intelswift.com Website (each referred to as a "Subscription Term"). The Customer will purchase, and the Company will deliver, the specific Services detailed in the Intelswift.com website.</p>

            <p>The Customer may access and use the Services solely for its own or his client benefit in accordance with the Terms and any usage scope restrictions specified.
                The Company provides AI-based assistance to the leads or clients of the Customer who visit the Customer's website or other platforms. Assistance of this nature is offered in accordance with the Customer's knowledge bank. The AI Assistant relies on information from the Knowledge bank and interacts with Customer's leads or clients. The Customer has the ability to assert control over the conversation and provide additional support to the client independently.
                Company’s services, inter-alia, include the following features, if they are directly specified within the Subscription you chose:</p>
            <ul>
                <li>Conversations credits – various interactions that take place between the AI Assistant and potential clients within a 24-hour period.</li>
                <li>Various Channels – you have the option to select how our AI Assistant will engage with your leads through Telegram and Web-widget.</li>
                <li>Up to 5 million characters knowledge database – AI Assistant can be trained using a vast amount of information.</li>
                <li>Live Chat – provides the opportunity to engage in real-time conversations with potential or current customers and intervene when needed.</li>
                <li>Enhanced human handoff – the AI Assistant will transfer the conversation to a Live Agent and provide a conversation summary, so you won't have to waste time reading the entire conversation.</li>
                <li>AI Copilot has the ability to generate a response by analyzing the conversation history and using your client's preferred language.</li>
                <li>AI Copilot has the capability to perform a segmentation to classify users into different categories, e.g. sales qualified lead, cross-sell qualified lead, and upsell qualified lead.</li>
                <li>Advanced Reporting – AI Copilot has the capability to generate comprehensive reports using the uploaded data.</li>
                <li>Integration with Calendly app, allowing to suggest convenient time slots for Customer’s prospects.</li>
                <li>Integration with Shopify app, allowing it to effortlessly recommend products from Customer’s Shopify store.</li>
                <li>Data forecasting – AI Copilot has the ability to accurately predict data based on the criteria you provide.</li>
                <li>Insights - AI Copilot is capable of generating a comprehensive report by analyzing the conversations with users, leads, and clients.</li>
            </ul>

            <p>Be aware that access to and use of the Services are permitted only by authorized users.
                If the Customer is provided with passwords to access the Services or API keys, the Customer shall ensure that all authorized users maintain the confidentiality of password information, API keys, user IDs and do not share such information with any other persons.
                User IDs and related credentials are issued to individuals and may not be shared. Be aware that the Customer shall be responsible for any and all actions taken using the Customer's accounts and passwords.
                If any authorized user with access to a user ID is no longer an employee (or contractor, as set forth below) of the Customer, the Customer shall promptly delete such user ID and terminate such authorized user's access to the Service.
                Using the Services, Customer is granted a limited, non-transferable, non-sublicensable, non-exclusive license during any applicable Subscription Term to use the Services internally. This license is granted subject to all of the Terms, unless otherwise indicated separately.
                If applicable, the Customer is required to deploy the Company Code on its properties to activate the relevant features of the Services. The Customer shall implement all necessary Company Code strictly in accordance with the Terms and any instructions provided by the Company.
                The Customer acknowledges that any modifications made to their properties after the initial implementation of the Company Code may cause the Services to stop working or to function improperly. The Company shall not be responsible for the effects of any such modifications made by the Customer.
                The Customer is authorized to designate its employees and contractors, as well as the employees and contractors of its affiliates, as Permitted Users, on condition that the Customer assumes responsibility for ensuring that these individuals comply with all the Terms. Any use of the Services by these Permitted Users must be solely for the benefit of the Customer.
                The Customer is prohibited from renting, leasing, providing access to, or sublicensing the services to any third party. Customer is also not allowed to use the Services to provide or incorporate them into any product or service for a third party, except for their own properties. Reverse engineering, decompiling, disassembling, or attempting to obtain the source code or non-public APIs of the services is strictly prohibited, unless expressly permitted by applicable law and with advance notice to the Company. Copying or modifying the services, documentation, or creating derivative works is not allowed. The customer must not remove or obscure any proprietary or other notices contained in the Services or on any reports or data printed from the Services, unless otherwise permitted by the Company in advance. Publicly disseminating information about the performance of the Services or using them for competitive analysis purposes is also prohibited.
                If the Company provides access to any APIs as part of the Services, the Company reserves the right to impose limits on such access and may monitor the Customer's usage of these APIs and restrict the number of calls or requests if Company believes that the Customer's usage breaches this Terms or may adversely affect the security, functionality, or integrity of the Services (or could otherwise result in liability for the Company).</p>

            <h3><strong>Customer Obligations</strong></h3>

            <p>The accuracy, content, and legality of all Customer Data are the sole responsibility of the Customer. The customer affirms to the Company that it has obtained all the required rights, consents, and permissions to collect, share, and use all Customer Data as specified in this agreement. Furthermore, the Customer assures that none of the Customer Data will violate or infringe upon any third-party intellectual property rights, publicity, privacy, or any other rights, nor will it contravene any laws or terms of service, privacy policies, or other agreements governing the Customer's properties or accounts with third-party platforms. The Customer is responsible for any data submitted to the services, as if it were submitted by them.
                The Parties must have a written agreement, specifically a business associate agreement for HIPAA data, if Customer wants to use the Services to collect, store, process, or transmit any Sensitive Personal Information. The Customer is aware that the Company does not function as a payment card processor and that the Services provided are not in compliance with PCI DSS standards. With the exception of Company’s obligations under any business associate agreement entered into with Customer, it is the responsibility of Customer to handle any Sensitive Personal Information submitted to the Service. Customer acknowledges that the Company is not bound by any additional obligations that may apply to such Sensitive Personal Information submitted to the Services.
                The Customer is required to comply with all relevant laws when using the services. Customer is expected to avoid any form of unsolicited advertising, marketing, or other activities using the Services. This includes complying with relevant anti-spam laws and regulations.
                It is important to note that Customer bears the sole responsibility for obtaining the required clearances, consents, and approvals from Customer's end users (clients) in accordance with all relevant laws. This ensures clarity and compliance.
                The Customer is responsible for protecting the Company from any claims, costs, damages, losses, liabilities, and expenses that may arise from their use of customer data, a third-party platform, or any breaches. This includes covering reasonable attorneys' fees and costs.
                The indemnification obligation is dependent on the Customer's receipt of: (i) The Customer must provide timely written notice of the claim, allowing sufficient time for a response without any negative impact; (ii) The Customer has the sole authority to oversee and manage the investigation, defense, or settlement of the claim; and (iii) The Company is obligated to provide all necessary cooperation, which will be at the Customer's expense. In accordance with the provision mentioned earlier, the Company retains the right to engage its own legal counsel (attorney, advocate, solicitor) and bear the associated costs in the event of a claim. Additionally, the Customer must obtain the Company's written consent before settling any claim, unless the settlement completely absolves the Company of any responsibility, financial obligation, or admission of liability.</p>

            <h3><strong>Security</strong></h3>

            <p>The Company undertakes to implement commercially reasonable technical and organizational measures designed to prevent unauthorized access to or use of the Services. However, the Company shall not be liable for errors in transmission, unauthorized third-party access, or other causes beyond the Company's control.</p>

            <h3><strong>Third-Party Platforms</strong></h3>

            <p>The Services may provide assistance with integrating with certain Third-Party Platforms. The Customer has the right to transfer Customer Data between the Services and compatible Third-Party Platforms using supported integrations.
                In order for the Services to seamlessly integrate with these Third-Party Platforms, the Customer may be required to provide their credentials. This will allow the Services to securely access and retrieve the necessary information. By allowing the Services to be used with any Third-Party Platform, the Customer gives the Company consent to access the Customer's accounts with such Third-Party Platform for the purposes stated in this Terms. It is the Customer's responsibility to comply with any relevant Terms of the Third-Party Platforms and ensure that they have appropriate accounts in good standing with the providers of the Third-Party Platforms.
                The Customer acknowledges and accepts that the Company bears no responsibility or liability for any Third-Party Platform, including beta releases or pre-release features, or for the manner in which a Third-Party Platform utilizes or handles Customer Data once it has been exported to said platform.
                The Company cannot provide a guarantee for the continuous maintenance of integrations with any Third-Party Platform. The Company also reserves the right to deactivate these integrations with the Services at any time, with or without prior notice to the Customer. This Terms control the Customer's use of and access to the Services, even if accessed through an integration with a Third-Party Platform.
                The Customer asserts and assures that they have consented to the terms of service associated with any Third-Party Messaging App(s) and have either established, or given the Company permission to establish on their behalf, a Third-Party Messaging App account in compliance with those Terms. The Customer's use of the Third-Party Messaging App account is governed by these Terms. The Company is not responsible for the network, functionality, clients, and APIs of the Third-Party Messaging App. It is the provider of the app who bears sole responsibility for these aspects.</p>

            <h3><strong>Ownership</strong></h3>

            <p>This document serves as a subscription agreement for accessing and using the Services. The Customer acknowledges that they are receiving only a limited right to use the Services and that, regardless of any use of terms such as "purchase," "sale," or similar terms in this agreement, no ownership rights are being transferred to the Customer under this Terms. The Customer agrees that the Company retain all rights, title, and interest (including all Intellectual Property Rights) in and to the Services, all documentation, integrations with the Services, any related and underlying technology and documentation, and any derivative works, modifications, or improvements of any of the foregoing, including any feedback.</p>

            <h3><strong>Subscription period</strong></h3>

            <p>The Service or some parts of the Service are available only with a paid Subscription. You will be billed in advance on a recurring and periodic basis (such as daily, weekly, monthly or annually), depending on the type of Subscription plan you select when purchasing the Subscription.
                At the end of each period, Your Subscription will automatically renew under the exact same conditions unless You cancel it or the Company cancels it.</p>

            <h3><strong>Subscription cancellations</strong></h3>

            <p>You may cancel Your Subscription renewal either through Your Account settings page or by contacting the Company. You will not receive a refund for the fees You already paid for Your current Subscription period and You will be able to access the Service until the end of Your current Subscription period.</p>

            <h3><strong>Billing</strong></h3>

            <p>You shall provide the Company with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information.
                Should automatic billing fail to occur for any reason, the Company will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>

            <h3><strong>Fee Changes</strong></h3>

            <p>The Company, in its sole discretion and at any time, may modify the Subscription fees. Any Subscription fee change will become effective at the end of the then-current Subscription period.
                The Company will provide You with reasonable prior notice of any change in Subscription fees to give You an opportunity to terminate Your Subscription before such change becomes effective.
                Your continued use of the Service after the Subscription fee change comes into effect constitutes Your agreement to pay the modified Subscription fee amount.
                All fees are non-refundable, unless otherwise stated in these Terms. The responsibility for paying all Taxes lies with the Customer, and it should be noted that these Taxes are not included in any fees specified in the order. If the Company determines that it has a legal obligation, it will send an invoice to the Customer for Taxes and any necessary fees that result from the Customer's use of the Services. The Customer is expected to fulfill their agreement to pay these Taxes and fees upon receiving the invoice. If the Customer is obligated to withhold any Taxes from their payment, the fees payable by the Customer will be adjusted to ensure that the Company receives the same amount it would have received if no withholdings had been made.</p>

            <h3><strong>Refunds</strong></h3>

            <p>Except when required by law, paid Subscription fees are non-refundable.
                Certain refund requests for Subscriptions may be considered by the Company on a case-by-case basis and granted at the sole discretion of the Company.</p>

            <h3><strong>Free Trial</strong></h3>

            <p>The Company may, at its sole discretion, offer a Subscription with a Free Trial for a limited period of time.
                You may be required to enter Your billing information in order to sign up for the Free Trial.
                If You do enter Your billing information when signing up for a Free Trial, You will not be charged by the Company until the Free Trial has expired. On the last day of the Free Trial period, unless You canceled Your Subscription, You will be automatically charged the applicable Subscription fees for the type of Subscription You have selected.
                At any time and without notice, the Company reserves the right to (i) modify the Terms of the Free Trial offer, or (ii) cancel such Free Trial offer.
                If the Customer is granted free access, a trial, or an evaluation subscription to the Service (Trial Subscription), the Customer may utilize the Services under the conditions outlined in this Section for a duration of fourteen (14) days or any other period specified by the Company ("Trial Period").
                Trial Subscriptions are authorized solely for the Customer's assessment to ascertain whether to procure a paid subscription to the Services or an upgrade to a service plan. Trial Subscriptions may not encompass all functionalities and features accessible during a paid Subscription Term and may be subject to usage limits.
                If the Customer does not commence a paid Subscription Term prior to the conclusion of the Trial Period, this Agreement and the Customer's entitlement to access and utilize the Services will conclude at the end of the Trial Period, unless otherwise specified herein.
                The company reserves the right to terminate a trial subscription at any time for any reason, notwithstanding anything to the contrary in this terms, the company will have no warranty, indemnity, support, service level agreement, or other obligations with respect to trial subscriptions.</p>

            <h3><strong>Promotions</strong></h3>

            <p>Any Promotions made available through the Service may be governed by rules that are separate from these Terms.
                If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.</p>

            <h3><strong>User Accounts</strong></h3>

            <p>When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on our Service.
                You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.
                You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.
                You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>

            <h3><strong>Your Right to Post Content</strong></h3>

            <p>Our Service allows You to post Content. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness.
                By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of Your rights to any Content You submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content subject to these Terms.
                You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</p>

            <h3><strong>Content Restrictions</strong></h3>

            <p>The Company is not responsible for the content of the Service's users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under Your account, whether done so by You or any third person using Your account.
                You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:
                Unlawful or promoting unlawful activity.
                Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.
                Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.
                Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.
                Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.
                Impersonating any person or entity including the Company and its employees or representatives.
                Violating the privacy of any third person.
                False information and features.</p>

            <p>The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with these Terms, refuse or remove this Content. The Company further reserves the right to make formatting and edits and change the manner of any Content. The Company can also limit or revoke the use of the Service if You post such objectionable Content. As the Company cannot control all content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.</p>

            <h3><strong>Content Backups</strong></h3>

            <p>Although regular backups of Content are performed, the Company does not guarantee there will be no loss or corruption of data.
                Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed.
                The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.
                You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.</p>

            <h3><strong>Copyright Policy</strong></h3>

            <h3><strong>Intellectual Property Infringement</strong></h3>

            <p>We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person.
                If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of our copyright agent via email at <a href="mailto:support@Intelswift.com">support@Intelswift.com</a> and include in Your notice a detailed description of the alleged infringement.
                You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright.</p>

            <h3><strong>DMCA Notice and DMCA Procedure for Copyright Infringement Claims</strong></h3>

            <p>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
                An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.
                A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.
                Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.
                Your address, telephone number, and email address.
                A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.
                A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf.
                You can contact our copyright agent via email at <a href="mailto:support@Intelswift.com">support@Intelswift.com</a>. Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Service.</p>

            <h3><strong>Intellectual Property</strong></h3>

            <p>The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors.
                The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.
                Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.</p>

            <h3><strong>Limited Warranty</strong></h3>

            <p>The Company's sole responsibility (and the Customer's exclusively remedy) for any violation will be, at no cost to the Customer, for the Company to make reasonable efforts to address the reported non-conformity. If the Company deems it unfeasible to pursue such a remedy, either party has the option to end the Subscription Term in question. If such a situation arises, the Customer will be entitled to a refund of any fees they have already paid for the terminated portion of the Subscription Term. This will be the Customer's only remedy in this case.
                The warranty outlined in this section is not applicable in certain situations. Firstly, the Customer must make a claim within thirty (30) days of discovering the non-conformity. Secondly, the warranty does not cover errors caused by misuse, unauthorized modifications, or the use of third-party hardware, software, or services. Lastly, if the Services are provided to the Customer for no charge, on a trial, beta, or evaluation basis, the warranty does not apply.</p>

            <h3><strong>"AS IS" and "AS AVAILABLE" Disclaimer</strong></h3>

            <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
                Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
                Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.
                The Company is not responsible for any consequences arising from the transmission or non-transmission of communications through the Services. The Company will not be held responsible for any delays, interruptions, service failures, or other issues that may arise from using the internet and electronic communications, third-party platforms, third-party apps, or any other systems beyond the Company's control.
                Moreover, the Company under any circumstances does not have any liability in the following cases: (1) if a third party modifies the service and the alleged infringement is caused by that modification; (2) if the service is combined with products or processes not provided by the company and the alleged infringement is caused by that combination; (3) for any unauthorized use of the service; (4) for any action arising from customer data or any third-party deliverables or components within the service; (5) if the alleged infringement is not caused by the specific technology or implementation of the service but instead by features common to any similar product or service; (6) for any action arising from the customer's use of third-party messaging apps; or (7) if the customer settles or admits to a claim without the company's prior written consent.
                The Customer may have additional legal rights, however, any warranties required by law will only last for the shortest period allowed by law.</p>

            <h3><strong>Confidential Information</strong></h3>

            <p>Both parties involved in these Terms, referred to as the "Receiving Party" and the "Disclosing Party," acknowledge that any code, inventions, know-how, business, technical, and financial information obtained from the Disclosing Party is considered confidential and proprietary ("Confidential Information"). This condition is applicable if the information is clearly marked as confidential when it is shared, or if it is reasonable for the receiving party to recognize it as confidential or proprietary based on the nature of the information and the circumstances surrounding its disclosure.
                The technology and performance information of any Service provided by the Company will be considered confidential, even without any explicit labeling or additional designation. The Receiving Party is obligated to maintain the confidentiality of any Confidential Information and is prohibited from disclosing it to third parties. Furthermore, the Receiving Party may only use the Confidential Information for the purpose of fulfilling its obligations and exercising its rights under these Terms.
                The Receiving Party may disclose Confidential Information to its employees, agents, contractors, and other representatives who have a legitimate need to know, provided that these representatives are obligated to maintain confidentiality to a degree that is equal to or greater than the obligations specified in this section. It is the responsibility of the Receiving Party to ensure that any representative they engage adheres to the Terms outlined in this section.
                The Receiving Party's obligations to keep information confidential do not extend to certain situations. These include instances where the information was already known or possessed by the Receiving Party before receiving the Confidential Information, when the information becomes public knowledge through no fault of the Receiving Party, when the information is lawfully obtained from a third party without any breach of confidentiality, or when the information is independently developed by employees of the Receiving Party who had no access to the Confidential Information.
                The Receiving Party may disclose Confidential Information if it is legally required to do so or if there is a court order. However, it must inform the Disclosing Party beforehand and work together to ensure that the disclosed information is treated as confidential.
                The Receiving Party understands the potential harm that would result from revealing Confidential Information. In the event of such a disclosure, the Disclosing Party has the right to pursue equitable relief along with any other legal remedies.</p>

            <h3><strong>Publicity</strong></h3>

            <p>Upon receiving Customer's explicit written permission, the Company is authorized to utilize Customer's name for the purpose of identifying them as a user of the Services, which may include displaying their name on the Company's public website. The Company is committed to adhering to any written guidelines provided by the Customer regarding the use of its name.</p>

            <h3><strong>Your Feedback to Us</strong></h3>

            <p>From time to time, the Customer may provide Feedback to the Company. The Company is free to use or exploit such Feedback in connection with the Services. The Customer hereby grants the Company a perpetual, non-exclusive, transferable, irrevocable, worldwide, royalty-free license (with rights to sublicense) to create, use, sell, offer to sell, reproduce, modify, distribute, make available, publicly display and perform, disclose, and otherwise commercially exploit the Feedback.
                You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.</p>

            <h3><strong>Links to Other Websites</strong></h3>

            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.
                The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.
                We strongly advise You to read the Terms and privacy policies of any third-party web sites or services that You visit.</p>

            <h3><strong>Termination</strong></h3>

            <p>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms.
                Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.</p>

            <h3><strong>Limitation of Liability</strong></h3>

            <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.
                To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
                Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>

            <h3><strong>General Terms</strong></h3>

            <p>This Terms will be legally binding and will apply to both parties and any successors or assigns. Both parties are prohibited from assigning these Terms without obtaining written consent from the other party, unless there is a merger, reorganization, acquisition, or transfer of a significant portion of that party's assets or voting securities. If this Terms is transferred or assigned without proper authorization, it will be deemed null and void.
                In the event that a court of competent jurisdiction deems any provision of these Terms unenforceable or invalid, that particular provision will be restricted to the minimum extent required to uphold the effectiveness of these Terms.</p>

            <h3><strong>Governing Law</strong></h3>

            <p>The laws of the Country, excluding its conflicts of law rules, shall govern these Terms and Your use of the Service. Your use of the Services may also be subject to other local, state, national, or international laws.
                If any dispute, claim, question, or disagreement arises from or relates to these Terms, the parties will first make efforts to resolve the matter amicably. In the event of a dispute, it is necessary for the party bringing up the matter to formally notify the other party through a written document called the "Initial Notice of Dispute." This document should clearly and comprehensively explain the nature of the dispute. When sending an Initial Notice of Dispute to the Company, please make sure to email it to <a href="mailto:support@Intelswift.com">support@Intelswift.com</a> and also send a physical copy via mail to: Harju maakond, Tallinn, Kesklinna linnaosa, Vesivärava tn 50-201, 10152.
                Upon receipt of the Initial Notice of Dispute, the parties will proceed to engage in discussions and negotiations in a sincere and cooperative manner. Given their mutual interest, they will work diligently to achieve a just and agreeable resolution of the dispute through direct dispute resolution. If the parties are unable to reach a resolution within thirty (30) days of receiving the initial notice of dispute, the matter may be brought before a court of law, as described below.
                Selection of applicable law and jurisdiction. Customer is required to submit any claim that is not subject to the Dispute Resolution provision to the personal and exclusive jurisdiction, as well as the exclusive venue courts located within Estonia, Tallinn. Estonian law shall apply to any dispute.
                These Terms should be interpreted as if they were collaboratively written by both parties. Any trial, arbitration, or claim under these Terms cannot be combined with any other trial, arbitration, or claim, even if it involves another user of the Services. Class trials and/or arbitration proceedings are also not permitted. In addition to the provisions mentioned, the Company retains the authority to pursue injunctive remedies or similar urgent legal relief in any jurisdiction.
                All notices or communications that are necessary or allowed under these Terms will be deemed received in the following circumstances.
                The notice will be considered received in different ways depending on the method of delivery. If it is sent by email, it will be considered received on the first business day after it is sent. Both parties acknowledge that electronic communication satisfies all legal requirements for written communication.
                These Terms represent the complete understanding between the parties involved and supersede any previous written or verbal agreements and communications concerning the subject matter at hand.
                Customer is aware that the Services provided are online, subscription-based products. It is understood that the Company has the right to make modifications to the Services in order to improve the customer experience and will update the relevant documentation accordingly.
                Both parties will not be held responsible for any delays or failures to meet their obligations under these Terms (excluding payment failures) if the delays or failures are caused by unforeseen events that occur after the agreement is signed and are beyond the reasonable control of either party. These events may include strikes, blockades, wars, acts of terrorism, riots, natural disasters, power or telecommunication network failures.
                The Company has the authority to disclose customer data as required by applicable laws, subpoenas, or court orders. Nevertheless, the Company will do its best to inform the Customer about any such disclosure, unless it is legally forbidden.
                This agreement does not establish any kind of partnership, joint venture, employment, franchise, or agency relationship between the parties involved. Both parties are required to obtain written consent from the other party before binding them or incurring any obligations on their behalf.
                Both parties are obligated to comply with all relevant export control and economic sanctions laws and regulations, including import laws and regulations of any jurisdictions in which they operate. Additionally, each party guarantees that it is not listed on any U.S. or other governmental list that prohibits or restricts their involvement, nor is it located in a country subject to imposed export control embargo or economic sanctions. It is important for the customer to understand that they are prohibited from accessing or using the Services in any way that violates U.S. export control or economic sanction laws. This includes not allowing any of their users to do so as well.
                You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>

            <h3><strong>For European Union (EU) Users</strong></h3>

            <p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which You are resident.</p>

            <h3><strong>United States Federal Government End Use Provisions</strong></h3>

            <p>If You are a U.S. federal government end user, our Service is a "Commercial Item" as that term is defined at 48 C.F.R. §2.101.</p>

            <h3><strong>Severability</strong></h3>

            <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>

            <h3><strong>Waiver</strong></h3>

            <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under these Terms shall not affect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.</p>

            <h3><strong>Translation Interpretation</strong></h3>

            <p>These Terms may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.</p>

            <h3><strong>Changes to These Terms</strong></h3>

            <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.
                By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>

            <h3><strong>Contact Us</strong></h3>

            <p>If you have any questions about these Terms, You can contact us:<br />
                By email: <a href="mailto:support@Intelswift.com">support@Intelswift.com</a><br />
                By visiting this page on our website: <a href="https://Intelswift.com">https://Intelswift.com</a></p>
        </section>
    );
}

export default Conditions;